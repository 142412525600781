<template>
  <div id="orgs">
    <b-card no-body>
      <b-row class="card-header" style="overflow: hidden">
        <b-col cols="6">
          <span class="card-title m-0">{{ msg('Organizations') }}</span>
        </b-col>
        <b-col cols="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" @click="createOrganization">
              <span class="text-nowrap">{{ msg('New Organization') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <!--Card Content-->
      <div v-if="loaded">
        <!--Table-->
        <table v-if="loaded" class="table b-table table-hover " v-bind:class="{'table-striped':loaded}">
          <thead>
          <tr>
            <!--Table header-->
            <th aria-sort="none"><a>{{ msg('ID') }}</a></th>
            <th aria-sort="none">{{ msg('Name') }}</th>
            <th aria-sort="none">{{ msg('Contact Name') }}</th>
            <th aria-sort="none">{{ msg('Administrator') }}</th>
            <th aria-sort="none">{{ msg('Phone') }}</th>
            <th aria-sort="none">{{ msg('Email') }}</th>
            <th aria-sort="none">{{ msg('price') }}</th>
            <th aria-sort="none">{{ msg('price type') }}</th>
            <th aria-sort="none"></th>
          </tr>
          </thead>
          <tbody>
          <!--Table-->
          <template v-if="loaded">
            <tr v-for="org in organizations" v-bind:key="org.id" >
              <td @click="onOrganizationSelect(org.id)">{{ org.id }}</td>
              <td @click="onOrganizationSelect(org.id)">{{ org.name }}</td>
              <td @click="onOrganizationSelect(org.id)">{{ org.contactName }}</td>
              <td @click="onOrganizationSelect(org.id)">{{ org.adminName }}</td>
              <td @click="onOrganizationSelect(org.id)">{{ org.phone }}</td>
              <td @click="onOrganizationSelect(org.id)">{{ org.email }}</td>
              <td @click="onOrganizationSelect(org.id)">{{ org.price > 0 ? org.price : '' }}</td>
              <td>{{ org.priceType }}</td>
              <td>
                <b-button variant="flat-success" class="btn-icon" @click="setOrg(org.id)">
                  <feather-icon icon="ArrowRightIcon"/>
                </b-button>
              </td>
            </tr>
          </template>
          <!--Loading-->
          <template v-else>
            <tr class="table-loading">
              <td colspan="9">
                <p>
                  <b-spinner label="Spinning"/>
                  {{ msg('loading') }}
                </p>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div v-else-if="error !== ''">
        <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
          <strong>{{ error }}</strong>
        </b-alert>
      </div>
      <div class="card-body" v-else>
        <div style="min-height: 300px;text-align: center">
          <div style="margin-top: 20%">
            <b-spinner label="Spinning"/>
            {{ msg('loading') }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'orgs',
  data() {
    return {
      loaded: false,
      error: '',
      filter: {},
      tableSort: {},
      organizations: [],
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
  },
  computed: {
    ...mapGetters('organizations', ['getTypes']),
    ...mapGetters('data', { _getCountry: 'getCountry' })
  },
  created() {
    this.refresh()
  },
  methods: {
    ...mapActions('organizations', ['getAll', 'reload', 'register','setOrg']),
    refresh() {
      let $this = this
      return this.getAll(this.filter)
          .then((organizations) => {
            console.log('orgs refresh', organizations)
            $this.organizations = organizations
            $this.loaded = true
          }, (e) => this.error = e)
    },
    onOrganizationSelect(id) {
      this.selected = id
      this.$router.push('/organization/edit/' + id)
    },
    filterUpdate() {
      this.selectedRows = []
      this.refresh({ filter: this.filter })
    },
    generateOrganizationPopup() {
      let $this = this
      return this.$swal({
        title: $this.msg('New organization name'),
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: $this.msg('Create'),
        showLoaderOnConfirm: true,
        preConfirm(name) {
          console.log('preConfirm', name)
          if (!name) return null
          return $this.register({ name })
        },
      })

    },
    createOrganization() {
      let $this = this
      this.generateOrganizationPopup()
          .then(result => {
            $this.refresh()
          }, (e) => console.log('createOrganization() error', e))
          .finally(() => $this.$swal.close())
    }

  }
}
</script>

<style>

</style>
